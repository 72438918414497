<template>
    <b-row>
        <b-col>
            <b-card>
                <b-row>
                    <!-- basic select -->
                    <b-col md="6">
                        <b-form-group label="Nama Bidang" label-for="basicInput">
                            <v-select v-model="input.bidang" label="nama_dinas" :options="rs_bidang"
                                @input="act_laodDatadinas()" />
                        </b-form-group>
                    </b-col>
                    <!-- select with icon -->
                    <b-col md="2">
                        <b-form-group label="Jenis ASB" label-for="basicInput">
                            <v-select v-model="input.jenisasb" :options="jennisAsb" label="label"
                                @input="act_laodData()">
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group label="Status ASB" label-for="basicInput">
                            <v-select v-model="input.statusasb" :options="statusAsb" label="label"
                                @input="act_laodData()">
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-button class="btn btn-warning mt-2 mr-1" @click="clearData()">
                            <i class="fa fa-refresh"></i>
                        </b-button>  
                        <a :href="server + 'download/laporan_asb_ssh/download' + '/'+input.bidang.id_master_kode_dinas + '/'+input.jenisasb.jensi_asb + '/' + input.statusasb.st_asb">
                            <b-button class="btn btn-info btn-md mt-2 mr-1"  v-if="view =='1'">
                                <i class="fa fa-download"></i>
                            </b-button> 
                        </a>
                    </b-col>
                </b-row>
            </b-card>
            <b-row>
                <b-col>
                    <list-data></list-data>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'

    import axios from '@/config/Axios'
    import {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardBody,
        BButton
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import ListData from './listData.vue'

    export default {
        components: {
            BRow,
            BCol,
            BFormGroup,
            vSelect,
            BCard,
            BCardBody,
            BButton,
            ListData
        },
        data() {
            return {
                rs_bidang: [],
                input: {
                    bidang: {},
                    jenisasb: {},
                    statusasb: {}
                },
                jennisAsb: [{
                        jensi_asb: '',
                        label: 'All'
                    },
                    {
                        jensi_asb: 'Fisik',
                        label: 'Fisik'
                    },
                    {
                        jensi_asb: 'Non Fisik',
                        label: 'Non Fisik'
                    },
                ],
                statusAsb: [{
                        st_asb: '',
                        label: 'All'
                    },
                    {
                        st_asb: 'Wajar',
                        label: 'Wajar'
                    },
                    {
                        st_asb: 'Tidak Wajar',
                        label: 'Tidak Wajar'
                    }
                ],
                id_master_kode_dinas: '',
                jenis_rumpun: '',
                status_asb: '',
                view : 0,
                server : localStorage.getItem('serverapp')
            }
        },
        computed: {
            ...mapState({
                rs_Data: state => state.assh.rs_Data,
            }),
        },
        mounted() {
            this.loadBidang();
        },
        methods: {
            ...mapMutations({
                set_rs_Data: 'set_rs_Data',
            }),
            clearData() {
                this.input = {
                    bidang: {},
                    jenisasb: {},
                    statusasb: {}
                };
                this.set_rs_Data([]);
                this.view =  0
            },
            act_laodDatadinas() {
                this.id_master_kode_dinas = this.input.bidang.id_master_kode_dinas;
                this.jenis_rumpun = "";
                this.input.jenisasb = {
                    jensi_asb: '',
                    label: 'All'
                };
                this.input.statusasb = {
                    st_asb: '',
                    label: 'All'
                };
                this.status_asb = "";
                this.dafatar_belanja_kegiatan();
            },
            act_laodData() {
                this.id_master_kode_dinas = this.input.bidang.id_master_kode_dinas;
                this.jenis_rumpun = this.input.jenisasb.jensi_asb;
                this.status_asb = this.input.statusasb.st_asb;
                this.dafatar_belanja_kegiatan();
            },
            async loadBidang() { 
                const self = this;                
                await axios({
                        method: 'PUT',
                        url: '/api/laporan/belanja/get_bidang',
                    })
                    .then(function (response) {
                        self.rs_bidang = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async dafatar_belanja_kegiatan() {
                this.view = 1;
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/laporan/belanja/dafatar_belanja_kegiatan',
                        data: {
                            id_master_kode_dinas: self.id_master_kode_dinas,
                            jenis_rumpun: self.jenis_rumpun,
                            status_asb: self.status_asb
                        }
                    })
                    .then(function (response) {
                        self.set_rs_Data(response.data.result)

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>