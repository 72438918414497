<template>
    <div> 
        <div class="row">
            <div class="col-sm-6">
                <!-- <h4><i class="fa fa-list mr-1"></i>{{st_asb.jenis_asb}} - {{ st_asb.status_asb }}</h4> -->
            </div>
            <!-- <div class="col-sm-6">
                <b-form-group class="">
                    <b-form-input id="smallInput" size="sm" v-model="cari" placeholder="Pencarian Sub Kegiatan" />
                </b-form-group>
            </div> -->
        </div>

        <div class="row font-small-3">
            <div class="col-xl-12 col-md-6 col-12" v-for="rs in rs_Data" v-bind:key="rs">
                <div class="card ">
                    <div class="card-body  ">
                        <div class="row">
                            <div class="col-sm-6">
                                <h6 v-if="rs.kode_sub_dinas !=''">Kode Sub Dinas :<br>
                                    <b>{{ rs.kode_sub_dinas.kode_dinas }}</b></h6>
                                <h6 v-if="rs.kode_sub_dinas !=''">Nama Sub Dinas :</h6>
                                <p class="" v-if="rs.kode_sub_dinas !=''">{{rs.kode_sub_dinas.nm_dinas  }}
                                </p>

                                <h6>Kode Kegiatan :<br> <b>{{ rs.kode_dinas.kode_dinas }} .
                                        {{ rs.kode_sub_kegiatan }}</b></h6>
                                <h6>Nama Sub Kegiatan :</h6>
                                <p class="">{{ rs.nama_sub_kegiatan }}
                                    <br>  <b>Kinerja :</b> {{ rs.kinerja }} <br>
                                     <b>Indikator :</b> 
                                    {{ rs.indikator }}<br><b>Satuan : </b> {{ rs.satuan }}</p>
                            </div>
                            <div class="col-sm-6 mr-l  font-small-3 "><span><b>Jenis ASB {{ rs.jenis_asb }} </b><br> <b>
                                        {{rs.nm_rumpun.kode_rumpun }} - {{rs.nm_rumpun.nama_rumpun }}</b></span> <br>
                                        
                                        <span>Batas Atas ASB : <b
                                        class="float-right">{{ formatPrice(rs.batas_atas_belanja) }}
                                    </b></span><br><span>Total Belanja RKA : <b class="float-right">
                                        {{ formatPrice(rs.total_belanja) }}
                                    </b></span>
                                <button type="button" v-show="rs.status_asb =='Wajar'"
                                @click="detailSubKegiatan(rs)"
                                            v-b-modal.detail_asb
                                    class="btn btn-sm btn-primary btn-block waves-effect waves-float waves-light"><i
                                        class="fa fa-check mr-1"></i>Wajar</button>
                                <button type="button" v-show="rs.status_asb =='Tidak Wajar'"
                                @click="detailSubKegiatan(rs)"
                                            v-b-modal.detail_asb
                                    class="btn btn-sm btn-danger btn-block waves-effect waves-float waves-light"><i
                                        class="fa fa-warning mr-1"></i>Tidak Wajar</button>

                                <button type="button" v-show="rs.status_asb =='Tidak Ada kesimpulan'"
                                @click="detailSubKegiatan(rs)"
                                            v-b-modal.detail_asb
                                    class="btn btn-sm btn-warning btn-block waves-effect waves-float waves-light"><i
                                        class="fa fa-warning mr-1"></i>Tidak Ada kesimpulan</button>

                                <div class="row mt-1">
                                    <div class="col-md-12 col-lg-12 col-xs-12"> 
                                            <button type="button" @click="detailSubKegiatan(rs)"
                                            v-b-modal.detail_asb
                                                class="btn btn-sm btn-info btn-block waves-effect waves-float waves-light"><i
                                                    class="fa fa-list mr-1"></i>Detail</button> 

                                    </div>
                                   
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <Detail_kegiatan @closebtn ="closebtn"></Detail_kegiatan>
    </div>
</template>

<script>
import Detail_kegiatan from './Detail_kegiatan.vue'
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive'
    import {
        BFormSelect,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BPagination,
        VBModal
    } from 'bootstrap-vue'

    export default {
        components: { 
            BFormSelect,
        Detail_kegiatan,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            BPagination,
        VBModal
        },

        data() {
            return {
                currentPage: 1,
                rows: 0,
                cari: ""
            }
        },
        mixins: [Base],

        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        watch: {
            cari(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
            currentPage(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.input.rs_dinas,
                dinas: state => state.input.dinas,
                rs_sub_kegiatan: state => state.input.rs_sub_kegiatan,
                sub_kegiatan: state => state.input.sub_kegiatan,
                count_data: state => state.input.count_data,
                st_asb: state => state.input.st_asb,
                rs_Data: state => state.assh.rs_Data, 
            }),
        },
        mounted() {
            this.load_data('', '');
            this.load_count();
        },
        methods: {
            ...mapMutations({
                set_rs_dinas: 'set_rs_dinas',
                set_dinas: 'dinas',
                set_rs_sub_kegiatan: "set_rs_sub_kegiatan",
                set_sub_kegiatan: "set_sub_kegiatan",
                set_count_data: 'set_count_data',
                set_st_asb: 'set_st_asb',
                set_rs_Data: 'set_rs_Data', 
            }),
            closebtn(){

            },
            // formatPrice(value) {
            //     let val = (value / 1).toFixed(0).replace('.', ',')
            //     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            // },
            detailSubKegiatan(val) {
                this.set_sub_kegiatan(val);
            },
            async load_data(cari, offset) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/get',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            cari: cari,
                            offset: offset,
                            jenis_asb: self.st_asb.jenis_asb,
                            status_asb: self.st_asb.status_asb
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_sub_kegiatan(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async load_count() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/count',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_count_data(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }
    }
</script>

<style>

</style>